@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';

.errorMessage {
  @include lightMode {
    --errorMessageBgColor: #f2dede;
    --errorMessageBorderColor: #{$bc-grey-200};
    --errorMessageColor: #{$bc-state-error};
  }

  color: var(--errorMessageColor);
  border: 1px solid var(--errorMessageBorderColor);
  background-color: var(--errorMessageBgColor);
  border-radius: 2px;
  padding: 15px 30px;
  font-size: 16px;
  margin: 0;

  &__details {
    display: block;
    margin: 0;
  }

  a {
    color: var(--errorMessageColor);
    margin: 0;
  }
}
