@import 'src/tokens/_themes.scss';
@import 'src/tokens/_colors.scss';
@import 'src/tokens/_spacing.scss';
@import 'src/tokens/_site.scss';
@import 'src/tokens/_shadows.scss';
@import 'src/tokens/_zIndex.scss';

.deals {
  @include lightMode {
    --dealsBorderColor: #{$bc-grey-500};
    --dealsHeaderBgColor: #{$bc-grey-600};
    --dealsHeaderTextColor: #{$bc-grey-100};
    --dealsTextColor: #{$bc-grey-600};
    --feedbackButtonIconColorHover: #{$bc-state-warning};
  }

  @media screen and (min-width: 1300px) {
    display: grid;
    grid-template-areas:
      'title filters'
      'subtitle filters'
      'dealList filters'
      'emptyGrid filters';
    grid-template-columns: 1fr 320px;
    grid-template-rows: auto auto auto 1fr;
  }

  &__title,
  &__subtitle,
  &__filters {
    color: var(--dealsHeaderTextColor);
    background-color: var(--dealsHeaderBgColor);
  }

  &__title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px 40px;
    padding: 16px 16px 8px;
    z-index: $zindex-sticky;

    @media screen and (min-width: 992px) {
      padding-top: 32px;
      border: 2px solid var(--dealsBorderColor);
      border-bottom: 0;
      margin: 0 16px;
    }

    @media screen and (min-width: 1300px) {
      position: sticky;
      top: 0;
      padding-bottom: 16px;
      border: 0;
      margin: 0;
    }

    &Text {
      display: flex;
      align-items: start;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      margin: 0;

      &Beta {
        font-weight: 300;
        line-height: 32px;
      }
    }
  }

  &__subtitle {
    grid-area: subtitle;
    padding: 0 16px 16px;
    border: 0;

    @media screen and (min-width: 992px) {
      border: 2px solid var(--dealsBorderColor);
      border-block: 0;
      margin: 0 16px;
    }

    @media screen and (min-width: 1300px) {
      margin: 0;
      border-inline: 0;
    }

    &Text {
      max-width: 500px;
      margin: 0;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 300;
      text-wrap: balance;
    }
  }

  &__feedbackButton {
    grid-area: feedback;
    align-self: start;
    box-shadow: $shadow-elevation-low;

    @media screen and (min-width: 1300px) {
      position: sticky;
      top: 32px;
    }

    &:hover {
      .baseButton__icon {
        color: var(--feedbackButtonIconColorHover);
      }
    }
  }

  &__filters {
    grid-area: filters;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 8px 16px 16px;
    border-bottom: 2px solid var(--dealsBorderColor);
    margin: 0;

    @media screen and (min-width: 992px) {
      border-radius: 0 0 8px 8px;
      margin: 0 16px;
      border-inline: 2px solid var(--dealsBorderColor);
    }

    @media screen and (min-width: 1300px) {
      position: static;
      height: calc(100% - 16px);
      padding-top: 160px;
      border-radius: 0 0 0 8px;
      border: 0;
      margin: 0;
    }

    &Label {
      line-height: 1;
    }

    .errorMessage {
      margin-top: 8px;
    }
  }

  &__dealsList {
    &__list {
      grid-area: dealList;
    }
  }

  &__emptyGrid {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: emptyGrid;
    padding: 16px;

    &Text {
      max-width: 500px;
      margin: 0;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 300;
      text-align: center;
      text-wrap: balance;
      color: var(--dealsTextColor);
    }

    &Button {
      margin-top: 16px;
      color: var(--dealsTextColor);
    }
  }

  &--empty {
    height: calc(100dvh - 595px);

    @media screen and (min-width: 992px) {
      height: calc(100dvh - $headerHeightDesktop - $footerHeightDesktop);
    }

    .deals__emptyGrid {
      display: flex;
    }
  }

  .multiSelectFilter {
    margin-top: 4px;

    @media screen and (min-width: 1300px) {
      position: sticky;
      top: 32px;
    }
  }
}
