$shadow-hue: 0;
$shadow-saturation: 0%;
$shadow-lightness: 0%;
$shadow-opacity: 0.15;

$shadow-elevation-low: 0px 1px 1.1px
    hsl($shadow-hue, $shadow-saturation, $shadow-lightness, 0.3),
  0px 1.5px 1.7px -1.2px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, 0.3),
  0px 3.5px 3.9px -2.5px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, 0.3);

$shadow-elevation-medium: 0px 1px 5px
    hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 3px 3.4px -0.8px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 7.3px 8.2px -1.7px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 17.5px 19.7px -2.5px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity);

$shadow-elevation-high: 0px 1px 1.1px
    hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 4.6px 5.2px -0.4px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 8.4px 9.5px -0.7px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 13.6px 15.3px -1.1px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 21.6px 24.3px -1.4px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 33.6px 37.8px -1.8px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0px 51px 57.4px -2.1px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity),
  0.1px 75px 84.4px -2.5px hsl($shadow-hue, $shadow-saturation, $shadow-lightness, $shadow-opacity);

$shadow-focus: rgba(0, 0, 0, 0.08) 0px 1px 1px inset,
  rgba(102, 175, 233, 0.6) 0px 0px 8px;
