@import 'src/tokens/_themes.scss';
@import 'src/tokens/_colors.scss';
@import 'src/tokens/_spacing.scss';
@import 'src/tokens/_shadows.scss';

.multiSelectFilter {
  @include lightMode {
    --borderColor: #{$bc-grey-200};
    --backgroundColor: #{$bc-white};
    --checkboxLabelColor: #{$bc-black};
    --checkboxLabelBgColorHover: #{$bc-grey-100}; // not used yet
    --checkboxLabelBgColorActive: #{$bc-grey-200}; // not used yet
    --dropdownBgColor: #{$bc-grey-100};
    --dropdownBgColorHover: #{$bc-grey-200}; // not used yet
    --dropdownBorderColor: #{$bc-grey-300};
    --dropdownBorderColorHover: #{$bc-grey-400};
    --dropdownShadow: #($shadow-elevation-medium);
    --dropdownTextColor: #{$bc-grey-700}; // not used yet
    --listItemBgColorHover: #{$bc-grey-200};
    --searchBorderColor: #{$bc-grey-200};
    --searchBorderColorFocus: #{$bc-blue-accent};
    --textColor: #{$bc-grey-700}; // not used yet
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-items: end;
  align-items: start;
  column-gap: 32px;

  &__dropdown {
    position: relative;
    flex: 0 0 auto;
    width: min(100%, 300px);

    &Toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-width: max-content;
      padding: 8px 12px;
      background-color: var(--backgroundColor);
      border: 1px solid var(--borderColor);
      border-radius: 4px;
      margin: 0;
      font-size: 14px;
      cursor: pointer;
      text-align: left;

      &:hover {
        border-color: var(--dropdownBorderColorHover);
      }

      &[aria-expanded='true'] {
        border-color: var(--dropdownBorderColorHover);
        background-color: var(--dropdownBgColor);
      }
    }

    &Menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background-color: var(--dropdownBgColor);
      border: 1px solid var(--dropdownBorderColor);
      border-radius: 4px;
      box-shadow: var(--dropdownShadow);
      z-index: 1;
      padding: 16px 8px;

      &Search {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        margin-bottom: 8px;
        border: 1px solid var(--searchBorderColor);
        border-radius: 4px;
        outline: none;

        &:focus {
          border-color: var(--searchBorderColorFocus);
        }
      }

      &List {
        display: flex;
        flex-direction: column;
        gap: 2px 8px;
        list-style: none;
        padding: 0;
        margin: 0;

        &Item {
          padding: 0;
          margin: 0;

          &:hover {
            background-color: var(--listItemBgColorHover);
          }

          &Label {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            color: var(--checkboxLabelColor);

            &Checkbox {
              margin-right: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
