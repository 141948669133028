@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';

.baseLabel {
  @include lightMode {
    --baseLabelColor: #{$bc-grey-600};
  }

  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  font-family: $label;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;
  color: var(--baseLabelColor);
}
