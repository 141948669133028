@import '/src/tokens/themes.scss';
@import '/src/tokens/colors.scss';

$gap: 2px;
$offset: $gap / 2 * -1;
$lineWidth: 2px;

.graphHeader {
  @include lightMode {
    --graphItemColor: #{$bc-grey-500};
    --graphBorderColor: #{$bc-grey-100};
    --graphTextSalesColor: #008080;
    --graphTextOrdersColor: #{$bc-grey-300};
    --graphTextPayoutsColor: #800080;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  grid-auto-rows: minmax(min-content, 1fr); // creates equal height rows
  align-items: stretch;
  gap: $gap;
  overflow: hidden;
  border-bottom: $lineWidth solid var(--graphBorderColor);
  margin-bottom: 40px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 8px;
    position: relative;
    padding: 24px;
    font-family: 'Prata', serif;
    color: var(--graphItemColor);

    &::before,
    &::after {
      content: '';
      background-color: var(--graphBorderColor); // "border" color
      position: absolute;
    }

    &::after {
      width: 100vw;
      height: $lineWidth;
      top: $offset;
      left: 0;
    }

    &::before {
      width: $lineWidth;
      height: 100vh;
      top: 0;
      left: $offset;
    }

    &Title {
      position: relative;
      font-size: 15px;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 700;
      line-height: 1.2;
      color: var(--graphItemColor);
      text-transform: capitalize;
      margin: 0;
    }

    &Amount {
      display: flex;
      align-items: baseline;
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      margin: 0;

      &:has(.baseIcon) {
        column-gap: 4px;
      }

      &[data-type='clicks'] {
        color: var(--graphItemColor);
      }

      &[data-type='sales'] {
        color: var(--graphTextSalesColor);
      }

      &[data-type='payouts'] {
        color: var(--graphTextPayoutsColor);
      }

      &[data-type='orders'] {
        color: var(--graphTextOrdersColor);
      }

      &Icon {
        padding: 0;
        color: var(--graphItemColor);

        & + .graphHeader__itemAmountSpan {
          color: var(--graphItemColor);
        }
      }
    }
  }
}
