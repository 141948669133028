@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';

.baseImage {
  @include lightMode {
    --defaultIconColor: #{$bc-grey-100};
    --imageBgColor: #{$bc-white};
  }

  display: block;
  width: 100%;
  height: auto;
  object-fit: var(--object-fit);
  background-color: var(--imageBgColor);

  &--loading {
    animation: fadeInOut 1.75s infinite;
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
