.contentChromeExtension {
  max-width: 800px;
  font-family: 'Nunito Sans', Arial, sans-serif;
}

.contentChromeExtension__headline3 {
  padding: 0 0 8px;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
}

.contentChromeExtension__headline4 {
  padding: 0 0 4px;
  margin: 0;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 1.2;
  font-weight: 700;
  font-style: italic;
}

.contentChromeExtension__body {
  margin: 0;
  padding-bottom: 24px;
  letter-spacing: -0.2px;
  line-height: 1.4;
}

.contentChromeExtension__link {
  font-weight: 600;
}

.contentChromeExtension__list {
  padding: 0 20px 24px;
  margin: 0;
}

.contentChromeExtension__separator {
  margin-top: 0;
}
