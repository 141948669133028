@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';

$duration: 1.5s;

.loadingDots {
  @include lightMode {
    --color1: #{$bc-blue-accent};
    --color2: #{$bc-blue-light-accent};
    --color3: #{$bc-green-dark-accent};
    --color4: #{$bc-orange-accent};
  }

  display: block;
  position: relative;
  height: var(--iconSize, 1em);
  width: calc(var(--iconSize, 1em) * 4 + 6px);

  &__dot {
    position: absolute;
    height: var(--iconSize, 1em);
    width: var(--iconSize, 1em);
    top: 0;

    &--one {
      left: 0;
      animation: dot1 $duration linear forwards infinite;
    }

    &--two {
      left: calc(var(--iconSize, 1em) + 2px);
      animation: dot2 $duration linear forwards infinite;
    }

    &--three {
      left: calc(var(--iconSize, 1em) * 2 + 4px);
      animation: dot3 $duration linear forwards infinite;
    }

    &--four {
      left: calc(var(--iconSize, 1em) * 3 + 6px);
      animation: dot4 $duration linear forwards infinite;
    }
  }
}

@keyframes dot1 {
  0% {
    color: var(--color1);
    transform: translate(0, 0);
  }
  9.09091% {
    color: var(--color1);
    transform: translate(0, calc(-100% - 2px));
  }
  18.18182% {
    transform: translate(calc(100% + 2px), calc(-100% - 2px));
    color: var(--color2);
  }
  27.27273% {
    transform: translate(calc(100% + 2px), 0);
  }
  100% {
    color: var(--color2);
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes dot2 {
  0% {
    color: var(--color2);
    transform: translate(0, 0);
  }
  18.18182% {
    transform: translate(0, 0);
  }
  27.27273% {
    transform: translate(0, calc(100% + 2px));
    color: var(--color2);
  }
  36.36364% {
    transform: translate(calc(100% + 2px), calc(100% + 2px));
    color: var(--color3);
  }
  45.45455% {
    transform: translate(calc(100% + 2px), 0);
  }
  100% {
    color: var(--color3);
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes dot3 {
  0% {
    color: var(--color3);
    transform: translate(0, 0);
  }
  36.36364% {
    transform: translate(0, 0);
  }
  45.45455% {
    transform: translate(0, calc(-100% - 2px));
    color: var(--color3);
  }
  54.54545% {
    transform: translate(calc(100% + 2px), calc(-100% - 2px));
    color: var(--color4);
  }
  63.63636% {
    transform: translate(calc(100% + 2px), 0);
  }
  100% {
    color: var(--color4);
    transform: translate(calc(100% + 2px), 0);
  }
}

@keyframes dot4 {
  0% {
    transform: translate(0, 0);
    color: var(--color4);
  }
  54.54545% {
    transform: translate(0, 0);
  }
  63.63636% {
    transform: translate(0, calc(100% + 2px));
    color: var(--color4);
  }
  72.72727% {
    color: var(--color3);
  }
  81.81818% {
    color: var(--color2);
  }
  90.90909% {
    transform: translate(calc(-300% - 6px), calc(100% + 2px));
    color: var(--color1);
  }
  100% {
    transform: translate(calc(-300% - 6px), 0);
    color: var(--color1);
  }
}
