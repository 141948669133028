@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_shadows.scss';

.baseTag {
  @include lightMode {
    --genericBgColor: #{$bc-green-light-deep};
    --genericBorderColor: #{$bc-green-light-accent};
    --genericTextColor: #{$bc-green-light-accent};
    --hotItemBgColor: #{$bc-green-light-accent};
    --hotItemBorderColor: #{$bc-blue-dark-med};
    --hotItemTextColor: #{$bc-blue-dark-med};
    --lowestPriceBgColor: #{$bc-blue-dark};
    --lowestPriceBorderColor: #{$bc-blue-light-accent};
    --lowestPriceTextColor: #{$bc-blue-light-accent};
    --trendingBgColor: #{$bc-blue-mid-deep};
    --trendingBorderColor: #{$bc-green-light-med};
    --trendingTextColor: #{$bc-green-light-med};
  }

  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  box-shadow: $shadow-elevation-low;

  &--generic {
    border: 1px solid var(--genericBorderColor);
    background-color: var(--genericBgColor);
    color: var(--genericTextColor);
  }

  &--trending {
    border: 1px solid var(--trendingBorderColor);
    color: var(--trendingTextColor);
    background-color: var(--trendingBgColor);
  }

  &--hotItem {
    border: 1px solid var(--hotItemBorderColor);
    color: var(--hotItemTextColor);
    background-color: var(--hotItemBgColor);
  }

  &--lowestPrice {
    border: 1px solid var(--lowestPriceBorderColor);
    color: var(--lowestPriceTextColor);
    background-color: var(--lowestPriceBgColor);
  }
}
