@import 'src/tokens/colors.scss';
@import 'src/tokens/themes.scss';

.footer {
  @include lightMode {
    --footerBgColor: #{$bc-grey-600};
    --footerTextColor: #{$bc-white};
    --footerHoverColor: #{$bc-green-mid};
  }

  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 16px 40px;
  padding: 24px 40px;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--footerTextColor);
  background-color: var(--footerBgColor);
  z-index: 10; // moves footer above left sidebar

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__copyright {
    margin: 0;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    text-wrap: balance;

    &Link {
      color: var(--footerTextColor);
      text-decoration: underline;
      transition: color 0.2s ease-out;

      &:hover {
        color: var(--footerHoverColor);
      }
    }
  }

  &__socials {
    display: flex;
    flex-direction: row;
    column-gap: 18px;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      &Link {
        display: flex;
        text-decoration: underline;
        color: var(--footerTextColor);
        transition: color 0.2s ease-out;

        &:hover {
          color: var(--footerHoverColor);
        }
      }
    }
  }
}
