@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';

.multiSelectFilterTags {
  @include lightMode {
    --carouselScrollbarThumbColor: #{$bc-grey-500};
    --carouselScrollbarTrackColor: #{$bc-grey-700};
    --carouselScrollbarWidth: thin;
    --tagBgColor: #{$bc-grey-200};
    --tagBgColorHover: #{$bc-grey-700};
    --tagTextColor: #{$bc-grey-700};
    --tagTextColorHover: #{$bc-grey-100};
    --tagIconCloseColor: #{$bc-grey-700};
    --tagIconCloseColorHover: #{$bc-grey-100};
  }

  justify-content: start;
  gap: 6px;
  padding: 0;
  margin: 0;

  &__item {
    list-style-type: none;
    line-height: 1;

    &Button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      width: max-content;
      padding: 3px 6px;
      margin: 0;
      color: var(--tagTextColor);
      background-color: var(--tagBgColor);
      border: 1px solid var(--tagBgColor);
      border-radius: 16px;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
      color: var(--tagTextColor);
      position: relative;
      scroll-padding: 0 8px;
      scroll-snap-align: start;
      cursor: pointer;
      transition: color 0.3s ease-out, background-color 0.2s ease-out;

      &Text {
        flex: 1 0 auto;
        line-height: 1;
      }

      .baseIcon {
        flex: 0 0 auto;
        color: var(--tagIconCloseColor);
      }

      &:hover {
        border-color: var(--tagIconCloseColorHover);
        color: var(--tagTextColorHover);
        background-color: var(--tagBgColorHover);

        .baseIcon {
          color: var(--tagIconCloseColorHover);
        }
      }
    }
  }

  &--carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    width: 100%;
    overflow-x: auto;
    padding: 16px 0 8px;
    scrollbar-color: var(--carouselScrollbarThumbColor)
      var(--carouselScrollbarTrackColor);
    scrollbar-width: var(--carouselScrollbarWidth);
    scroll-snap-type: x mandatory;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
    overflow-x: visible;
    margin-top: 16px;
  }
}
