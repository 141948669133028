@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';
@import '/src/tokens/_animation.scss';
@import '/src/tokens/_zindex.scss';

.loadingOverlay {
  --loadingOverlayZIndex: #{$zindex-overlay};

  @include lightMode {
    --loadingOverlayBgColor: rgba(#{$bc-white}, 0.6);
    --loadingOverlayIconColor: #{$bc-black};
  }

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loadingOverlayBgColor);
  opacity: 0.9;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: var(--loadingOverlayZIndex);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 60px;
    color: var(--loadingOverlayIconColor);
    animation: spin 800ms linear infinite;
  }

  &--clear {
    .loadingOverlay__icon {
      color: transparent;
    }
  }
}
