.supportTabs__tabContent {
  position: relative;
}

.supportTabs__tab .supportTabs__tabContentTitle {
  color: #000000;
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 32px 0 0;
  margin: 0;
  text-transform: uppercase;
}

.supportTabs__tab .supportTabs__tabContentSubtitle {
  max-width: 600px;
  color: #000000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 25px;
  margin: 0;
  padding: 8px 0 0;
}

.supportTabs__tab .supportTabs__tabContentForm {
  max-width: 600px;
  padding: 16px 0 0;
  margin: 0;
}
