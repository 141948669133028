@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';

$buttonHeight: 40px;

.buttonCopyLink {
  @include lightMode {
    --buttonCopyBorder: 2px dashed #{$bc-orange-accent};
    --buttonCopyTextColor: #{$bc-grey-600};
    --iconCopyHoverColor: #{$bc-orange-accent};
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  width: max-content;
  min-height: $buttonHeight;
  padding: 6px 12px;
  border: var(--buttonCopyBorder);
  border-radius: 4px;
  margin: 0;
  color: var(--buttonCopyTextColor);
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.05px;
  cursor: pointer;

  &__affLink {
    @include truncate(14px);
    display: inline-block; // needed for truncation
  }

  &__icon {
    flex: 0 0 auto;
    margin: 0 0 2px;
    transition: color 200ms ease-out;
  }

  &:hover {
    .buttonCopyLink__icon {
      color: var(--iconCopyHoverColor);
    }
  }
}
