@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';

.pagination {
  @include lightMode {
    --paginationBorderColor: #{$bc-grey-400};
    --paginationBorderColorHover: #{$bc-green-dark-med};
    --paginationItemBgColor: #{$bc-white};
    --paginationItemBgColorHover: #{$bc-grey-100};
    --paginationItemBgColorCurrent: #{$bc-black};
    --paginationItemColor: #{$bc-grey-600};
    --paginationItemColorCurrent: #{$bc-white};
    --paginationItemColorDisabled: #{$bc-grey-100};
  }

  --paginationIconSize: 20px;
  --paginationGap: 16px;
  --paginationBoxSize: 36px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--paginationGap);
  list-style: none;
  width: 100%;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--paginationBoxSize);
    min-width: var(--paginationBoxSize);
    border: 1px solid var(--paginationBorderColor);
    background-color: var(--paginationItemBgColor);
    color: var(--paginationItemColor);
    margin: 0;
    font-family: $heading;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    padding: 4px 7px 0;
    font-size: 16px;
    transition: background-color 0.2s ease-out;

    &:disabled {
      color: var(--paginationItemColorDisabled);
      cursor: default;
    }

    &:hover {
      background-color: var(--paginationItemBgColorHover);
    }

    &--current {
      background-color: var(--paginationItemBgColorCurrent);
      font-weight: 700;
      border: none;
      color: var(--paginationItemColorCurrent);
      cursor: default;

      &:hover {
        background-color: var(--paginationItemBgColorCurrent);
      }
    }

    &--prev,
    &--next,
    &--dots {
      border: none;
      padding: 0;
      min-width: auto;
      height: auto;

      &:hover {
        background-color: var(--paginationItemBgColor);
      }
    }

    &--dots {
      align-self: end;

      .baseIcon {
        font-size: var(--paginationBoxSize);
        padding-bottom: 2px;
      }
    }

    &--prev,
    &--next {
      font-size: var(--paginationIconSize);
      transition: transform 0.2s ease-out;
    }

    &--prev {
      transform: rotate(90deg) translateY(0);

      &:not(:disabled):hover {
        transform: rotate(90deg) translateY(3px);
      }
    }

    &--next {
      transform: rotate(-90deg) translateY(0);

      &:not(:disabled):hover {
        transform: rotate(-90deg) translateY(3px);
      }
    }
  }
}
