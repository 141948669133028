// create sass typography tokens and mixins here based on the lower thirds scale
//
$font-size-1: 11px;
$font-size-2: 13px;
$font-size-3: 16px;
$font-size-4: 19px;
$font-size-5: 23px;
$font-size-6: 28px;
$font-size-7: 33px;
$font-size-8: 40px;
$font-size-9: 48px;
$font-size-10: 58px;

$line-height-1: 1.2;
$line-height-2: 1.4;

$font-weight-1: 400;
$font-weight-2: 500;
$font-weight-4: 700;

$heading: 'Josefin Sans', sans-serif;
$body: 'Nunito Sans', sans-serif;
$deco: 'Dancing Script', cursive;
$quote: 'Prata', serif;
$label: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$postContent: 'Lato', sans-serif;
$page: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
  Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol, Noto Color Emoji;
$monospace: Courier, sans-serif;

$headline-text-spacing: 0.1px;
$body-text-spacing: -0.05px;

@mixin typography(
  $font-size,
  $line-height,
  $font-weight,
  $font-family,
  $text-spacing
) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  font-family: $font-family;
  letter-spacing: $text-spacing;
}

@mixin headline-1 {
  @include typography(
    $font-size-10,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin headline-2 {
  @include typography(
    $font-size-9,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin headline-3 {
  @include typography(
    $font-size-8,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin headline-4 {
  @include typography(
    $font-size-7,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin headline-5 {
  @include typography(
    $font-size-6,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin headline-6 {
  @include typography(
    $font-size-5,
    $line-height-1,
    $font-weight-4,
    $heading,
    $headline-text-spacing
  );
}

@mixin body-1 {
  @include typography(
    $font-size-3,
    $line-height-2,
    $font-weight-1,
    $heading,
    $body-text-spacing
  );
}

@mixin body-2 {
  @include typography(
    $font-size-2,
    $line-height-2,
    $font-weight-1,
    $heading,
    $body-text-spacing
  );
}

@mixin body-3 {
  @include typography(
    $font-size-1,
    $line-height-2,
    $font-weight-1,
    $heading,
    $body-text-spacing
  );
}

@mixin caption {
  @include typography(
    $font-size-1,
    $line-height-2,
    $font-weight-1,
    $heading,
    $body-text-spacing
  );
}

@mixin button {
  @include typography(
    $font-size-3,
    $line-height-2,
    $font-weight-2,
    $heading,
    $body-text-spacing
  );
}

@mixin code {
  @include typography(
    $font-size-3,
    $line-height-2,
    $font-weight-1,
    $font-family-monospace,
    $body-text-spacing
  );
}

@mixin truncate($line-height, $line-number: 1, $vertical-padding: 0px) {
  -webkit-line-clamp: $line-number;
  max-height: calc(($line-height * $line-number) + $vertical-padding);
  line-height: $line-height;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
