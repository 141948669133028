@import 'src/tokens/_spacing.scss';

.dealsList {
  list-style-type: none;
  margin: 0;
  padding: $bc-spacing-3;
  // TODO add font-family

  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: $bc-spacing-3;
  }
}
