@import '/src/tokens/themes.scss';
@import '/src/tokens/colors.scss';
@import '/src/tokens/_forms.scss';
@import '/src/tokens/zIndex.scss';

.baseInputDate {
  @include lightMode {
    --inputDateIconBgColor: #{$bc-blue-dark};
    --inputDateIconColor: #{$bc-white};
  }

  position: relative;

  &__calendar {
    max-width: 250px;
    position: absolute;
    z-index: $zindex-docked;
    top: calc(100% + 3px);
    left: 0;
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px;
    background-color: var(--inputDateIconBgColor);
    color: var(--inputDateIconColor);
    border-radius: 0 $inputBorderRadius $inputBorderRadius 0;
  }
}
