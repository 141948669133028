@import '/src/tokens/colors.scss';

.sideMenu {
  [href='/trending'] span {
    position: relative;

    &::after {
      content: 'New';
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px 4px 2px;
      background-color: #dff0ee;
      color: #1a3f2e;
      border: 1px solid #1a3f2e;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      border-radius: 10px;
      transform: translate(90%, -85%);
      letter-spacing: -0.4px;
    }
  }
}
