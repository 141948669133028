.value input {
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-size: var(--fontSizes-size15);
  font-family: var(--fonts-body);
  font-weight: var(--fontWeights-bold);
  line-height: 1.37;
  border: 1px solid var(--colors-blue7);
  border-radius: 1px;
  width: 100%;
  max-width: 100%;
  min-height: 44px;
  max-height: fit-content;
  margin: 10px 0px;
  color: rgb(102, 102, 102);
}
