@import 'src/tokens/_site.scss';

.layout {
  &__wrapper:has(.deals) {
    padding-bottom: 0;
    margin-inline: 0;
    max-width: 100%;
  }

  &__layout:has(.deals) {
    min-height: calc(100% - $headerHeightMobile - $footerHeightMobile);
    padding: 0;

    @media screen and (min-width: 1300px) {
      min-height: calc(100% - $headerHeightDesktop - $footerHeightDesktop);
    }
  }
}
