.baseIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--iconSize, 1em);
  height: var(--iconSize, 1em);
  color: currentColor;

  svg {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    fill: currentColor;
    pointer-events: none;
  }
}
