@import 'src/tokens/_themes.scss';
@import 'src/tokens/_colors.scss';
@import 'src/tokens/_shadows.scss';

.multiSelectFilterDropdown {
  @include lightMode {
    --borderColor: #{$bc-grey-200};
    --checkboxLabelColor: #{$bc-black};
    --dropdownBgColor: #{$bc-grey-100};
    --dropdownBorderColor: #{$bc-grey-300};
    --dropdownBorderColorHover: #{$bc-grey-400};
    --dropdownShadow: #($shadow-elevation-medium);
    --iconColor: #{$bc-grey-400};
    --inputBgColor: #{$bc-white};
    --listItemBgColorHover: #{$bc-grey-200};
    --searchBorderColor: #{$bc-grey-200};
    --searchOutlineColor: #007aff;
    --selectAllColor: #007aff;
  }

  position: relative;
  flex: 0 0 auto;
  width: min(100%, 300px);

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: max-content;
    min-height: 40px;
    padding: 8px 12px;
    background-color: var(--inputBgColor);
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    margin: 0;
    font-size: 14px;
    cursor: pointer;
    text-align: left;

    &:hover {
      border-color: var(--dropdownBorderColorHover);
    }

    &Icon {
      transform: rotate(0deg);
      transition: transform 200ms ease-out;
    }

    &[aria-expanded='true'] {
      .multiSelectFilterDropdown__toggleIcon {
        transform: rotate(180deg);
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--dropdownBgColor);
    border: 1px solid var(--dropdownBorderColor);
    border-radius: 4px;
    box-shadow: var(--dropdownShadow);
    z-index: 1;
    padding: 16px 8px 8px;
    margin: 8px 0 0;

    &Search {
      display: flex;
      align-items: center;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 8px;
      font-size: 16px;
      border: 1px solid var(--searchBorderColor);
      background-color: var(--inputBgColor);

      &:has(.multiSelectFilterDropdown__menuSearchInput:focus) {
        outline: 2px solid var(--searchOutlineColor);
      }

      &Input {
        width: 100%;

        outline: none;
        flex: 1;
        border: none;
        outline: none;
        background-color: transparent;
      }

      &Icon {
        color: var(--iconColor);
        margin-right: 8px;
      }
    }

    &List {
      display: flex;
      flex-direction: column;
      gap: 2px 8px;
      list-style: none;
      padding: 0;
      margin: 0;

      &Item {
        padding: 0;
        margin: 0;

        &Label {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          color: var(--checkboxLabelColor);
          transition: background-color 0.2s ease-out;

          &:hover {
            background-color: var(--listItemBgColorHover);
          }

          &--selectAll {
            color: var(--selectAllColor);
            margin-bottom: 8px;
          }

          &Hr {
            margin: 0 0 4px;
            border: none;
            border-top: 1px solid var(--dropdownBorderColor);
            overflow: visible;
            text-align: center;
            height: 1px;
          }

          &Checkbox {
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
