.form-container {
  /* background-color: #006edc; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
}

.form-container label {
  /* background-color: #61dafb; */
  display: grid !important;
  grid-template-columns: 1fr 64%;
}

/* .links-section { */
/*  background-color: #d10000; */
/* } */

.links-section label {
  display: block !important;
}

.links-section #deepLinking {
  padding-left: 0;
}

.form-container .build-a-link-form {
  width: 52%;
}

.form-container .errorStyle {
  color: #a94442;
  border: 1px solid #ebccd1;
  background-color: #f2dede;
  border-radius: 2px;
  padding: 15px 30px;
}

.ThreePart {
  display: grid;
  grid-template-columns: 80% 1fr;
  gap: 1rem;
}
