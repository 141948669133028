@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_shadows.scss';
@import '/src/tokens/_spacing.scss';
@import '/src/tokens/_typography.scss';

.dealsListItem {
  $buttonHeight: 40px;
  $buttonMarginTop: 4px;
  $panelRowGap: 2px;
  $validDatesPaddingTop: 2px;
  $validDatesHeight: 12px;

  @include lightMode {
    --cardBgColor: #{$bc-grey-100};
    --cardBorderColor: #{$bc-grey-200};
    --cardShadow: #{$shadow-elevation-medium};
    --cardTextColor: #{$bc-grey-600};
    --iconNewTabColor: #{$bc-grey-500};
    --iconCopyHoverColor: #{$bc-orange-accent};
    --imageBgColor: #{$bc-grey-100};
  }

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  border-radius: 8px;
  border: 1px solid var(--cardBorderColor);
  margin: 0;
  background-color: var(--cardBgColor);
  color: var(--cardTextColor);
  line-height: 1.2;
  font-weight: 300;
  box-shadow: var(--cardShadow);

  &__commission {
    @include truncate(14px, 1, 21px);
    padding: 8px 8px 13px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__image {
    position: relative;
    display: block;
    margin: 0;
    border-block: 1px solid var(--cardBorderColor);

    .baseImage {
      margin: 0;
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: var(--imageBgColor);
      mix-blend-mode: darken;
    }
  }

  .baseTag {
    position: absolute;
    top: 35px;
    left: 8px;
  }

  &__panel {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: $panelRowGap;
    height: 100%;
    padding: 8px;
    margin: 0;

    &Title {
      display: block;
      font-size: 16px;
      margin: 0 0 auto;
      color: var(--cardTextColor);
      text-decoration: none;

      &Text {
        @include truncate(21px, 3);
        margin: 0;
      }

      .baseIcon--newTab {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        color: var(--iconNewTabColor);
        padding: 4px;
        transition: opacity 300ms ease-out;
      }

      &:hover {
        text-decoration: underline;

        .baseIcon--newTab {
          opacity: 1;
        }
      }
    }

    &Brand {
      @include truncate(14px);
      margin: 0;
      font-size: 12px;
    }

    &Price {
      margin: 0;
      font-size: 16px;
      font-weight: 700;

      &Original {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        font-style: italic;
        text-decoration: line-through;
      }
    }

    &ValidDates {
      @include truncate(12px, 1, $validDatesPaddingTop);
      padding-top: $validDatesPaddingTop;
      margin: 0;
      font-size: 10px;
      line-height: $validDatesHeight;
      font-weight: 500;
      text-align: right;
    }

    &Code {
      @include truncate(14px);
      margin: 0;
      font-size: 12px;

      &Text {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &Error {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 4px 12px;
      margin: $buttonMarginTop 0 0;
      border-radius: 4px;
      border-width: 2px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      min-height: $buttonHeight;
    }

    .buttonCopyLink {
      width: 100%;
      margin: $buttonMarginTop 0 0;
    }

    .buttonCreateLink {
      width: 100%;
      margin-bottom: calc(
        $validDatesPaddingTop + $validDatesHeight + $panelRowGap
      ); // reserves space for valid dates when not displayed
      margin-top: calc(
        $buttonHeight + $panelRowGap + ($buttonMarginTop * 2)
      ); // reserves space for copyLink button and error message when not displayed

      &:has(+ .dealsListItem__panelValidDates) {
        margin-bottom: 0;
      }
    }

    // removes reserved space if valid dates are present
    .buttonCopyLink + .buttonCreateLink,
    .dealsListItem__panelError + .buttonCreateLink {
      margin-top: $buttonMarginTop; // removes reserved space for CopyLink button or error message when displayed
    }

    // pushes bottom button content down depending which optional elements are present
    &:has(.dealsListItem__panelPrice) {
      .dealsListItem__panelPrice {
        margin: 0 0 auto;
      }

      .dealsListItem__panelCode {
        margin: 0;
      }

      .dealsListItem__panelTitle {
        margin: 0;
      }
    }

    &:has(.dealsListItem__panelCode) {
      .dealsListItem__panelCode {
        margin: 0 0 auto;
      }

      .dealsListItem__panelPrice {
        margin: 0;
      }

      .dealsListItem__panelTitle {
        margin: 0;
      }
    }
  }

  &__image,
  &__panelTitle {
    .baseIcon--newTab {
      position: absolute;
      top: 2px;
      right: 2px;
      opacity: 0;
      color: var(--iconNewTabColor);
      padding: 4px;
      transition: opacity 300ms ease-out;
    }

    &:hover {
      text-decoration: underline;

      .baseIcon--newTab {
        opacity: 1;
      }
    }
  }
}
