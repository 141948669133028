@import '/src/tokens/themes.scss';
@import '/src/tokens/_colors.scss';

.topBar {
  @include lightMode {
    --topBarBgColor: #{$bc-grey-100};
  }

  display: none;
  width: 100%;
  text-align: center;
  background: var(--topBarBgColor);

  @media (min-width: 992px) {
    display: block;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 11px 0 12px;
    margin: 0 auto;
    transition: 0.3s ease-in;
    transition-delay: 100ms;
    transition-property: padding;

    @media screen and (min-width: 992px) {
      max-width: 900px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1080px;
    }

    &Socials a div {
      margin: 0;
    }

    &Links {
      display: flex;
      align-items: center;
      transition: 0.3s ease-in;
      transition-delay: 100ms;
      transition-property: font;

      &Apply {
        display: block;
        line-height: 28px;
        transition: 0.3s ease-in;
        transition-delay: 100ms;
        transition-property: font;
      }
    }
  }

  &--scroll {
    .topBar__inner {
      padding: 5px 0;

      &Socials a {
        font-size: 16px;
      }

      &Links {
        &Apply {
          font-size: 18px;
          transition: 0.3s ease-in;
          transition-delay: 100ms;
          transition-property: font;
        }
      }
    }
  }
}
