@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';

.blog {
  @include lightMode {
    --blogPanelTitleColor: #{$bc-grey-600};
  }

  max-width: 1255px;

  &__title {
    font-family: $heading;
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    padding-bottom: 32px;
    margin: 0;
  }

  &__top {
    margin: 0;
  }

  &__panel {
    margin-top: 80px;

    &Title {
      font-family: $heading;
      font-size: 19px;
      font-weight: 700;
      line-height: 1;
      color: var(--blogPanelTitleColor);
      margin: 0;
      padding: 0 0 16px;
      text-align: center;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 32px 0 0;
  }
}
