@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_typography.scss';

.baseButton {
  @include lightMode {
    --buttonTextColor: #{$bc-grey-100};
    --buttonIconColor: #{$bc-grey-100};
    --buttonBgColorDisabled: #{$bc-grey-400};
    --buttonPrimaryBgColor: #{$bc-blue-dark};
    --buttonPrimaryBgColorHover: #{$bc-blue-dark-med};
    --buttonPrimaryBgColorActive: #{$bc-blue-mid-deep};
    --buttonSecondaryBgColor: #{$bc-green-dark};
    --buttonSecondaryBgColorHover: #{$bc-green-dark-med};
    --buttonSecondaryBgColorActive: #{$bc-green-mid-deep};
    --buttonBlackBgColor: #{$bc-grey-700};
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: max-content;
  min-height: 40px;
  padding: 6px 12px;
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 200ms ease-out;

  &__text {
    @include truncate(28px);
    color: var(--buttonTextColor);
  }

  &__icon {
    flex: 0 0 auto;
    margin: 0 0 2px;
    color: var(--buttonIconColor);
    transition: color 200ms ease-out;
  }

  &--primary {
    background-color: var(--buttonPrimaryBgColor);

    &:hover {
      background-color: var(--buttonPrimaryBgColorHover);
    }

    &:active {
      background-color: var(--buttonPrimaryBgColorActive);
    }
  }

  &--secondary {
    background-color: var(--buttonSecondaryBgColor);

    &:hover {
      background-color: var(--buttonSecondaryBgColorHover);
    }

    &:active {
      background-color: var(--buttonSecondaryBgColorActive);
    }
  }

  &--black {
    background-color: var(--buttonBlackBgColor);
  }

  &[disabled] {
    cursor: not-allowed;

    // only change to disabled bg color after loading dots are finished
    &:not(:has(.loadingDots)) {
      background-color: var(--buttonBgColorDisabled);
    }
  }

  &--iconRight {
    flex-direction: row-reverse;
  }
}
