@import '/src/tokens/_themes.scss';
@import '/src/tokens/_colors.scss';
@import '/src/tokens/_shadows.scss';
@import '/src/tokens/_forms.scss';
@import '/src/tokens/_typography.scss';

.baseInput {
  @include lightMode {
    --baseInputBorderColor: #cfdadc;
    --baseInputBorderColorFocus: #66afe9;
  }

  width: 100%;
  height: $inputHeight;
  padding: 6px 64px 6px 12px;
  border: 1px solid var(--baseInputBorderColor);
  border-radius: $inputBorderRadius;
  margin: 4px 0 0;
  font-family: $body;
  font-size: 16px;
  background: transparent;
  appearance: none;
  cursor: pointer;

  &::-webkit-calendar-picker-indicator,
  &::-webkit-input-placeholder {
    display: none;
    visibility: hidden !important;
  }

  // TODO - update legacy focus styles after css-in-js is removed
  &:focus {
    outline: 0;
    border-color: var(--baseInputBorderColorFocus);
    box-shadow: $shadow-focus;
  }
}
